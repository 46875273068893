<template>
  <section>
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-row class="justify-content-end">
            <b-col sm="6" md="3" lg="3" class="mb-1 mb-md-0">
              <v-select
                size="sm"
                v-model="pagination.perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="pagination.perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
                @input="onChange"
              />
              <label>entries per page</label>
            </b-col>
            <b-col sm="6" md="6" lg="6" class="mb-1 mb-md-0">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  placeholder="Search"
                  v-model="search"
                  @input="onFiltersChange"
                />
              </b-input-group>
            </b-col>
            <b-col sm="6" md="3" lg="3" class="mb-1 mb-md-0">
              <b-button
                block
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="w-100"
                @click="
                  () => {
                    $router.push('/role/form/new');
                  }
                "
              >
                Add Role
              </b-button>
            </b-col>
          </b-row>

          <div class="box mt-2">
            <b-table
              bordered
              striped
              hover
              responsive
              class="position-relative"
              primary-key="id"
              empty-text="No matching records found"
              show-empty
              :per-page="pagination.perPage"
              :current-page="pagination.currentPage"
              :items="RoleLists"
              :fields="fields"
            >
              <template #cell(sr)="data">
                {{
                  data.index +
                  1 +
                  (pagination.currentPage - 1) * pagination.perPage
                }}
              </template>
              <template #cell(role_is_active)="data">
                <b-form-checkbox
                  name="check-button"
                  switch
                  inline
                  :checked="data.item.role_is_active"
                  @change="activateRole(data.item)"
                >
                </b-form-checkbox>
              </template>
              <template #cell(edit)="data">
                <feather-icon
                  icon="EditIcon"
                  @click="
                    () => {
                      $router.push(`/role/form/${data.item.role_id}`);
                    }
                  "
                />
              </template>
            </b-table>
          </div>

          <template #footer>
            <div>
              <b-row>
                <b-col
                  cols="12"
                  class="d-flex align-items-end justify-content-end justify-content-sm-end"
                >
                  <b-pagination
                    v-model="pagination.currentPage"
                    :total-rows="RoleLists.length"
                    :per-page="pagination.perPage"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mb-0"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </template>

          <!-- <b-card class="d-flex justify-content-between flex-wrap pt-0">
             pagination
            <div>
              <b-pagination
                v-model="pagination.currentPage"
                :total-rows="RoleLists.length"
                :per-page="pagination.perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </b-card> -->
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BInputGroupPrepend,
  BCard,
  BTable,
  BInputGroupAppend,
  BButton,
  BButtonGroup,
  BDropdown,
  BRow,
  BCol,
  BContainer,
  BFormGroup,
  BPagination,
  BInputGroup,
  BFormInput,
  BForm,
  BFormCheckbox,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import * as UserServices from "@/apiServices/UsersServices";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    vSelect,
    FeatherIcon,
    BInputGroupPrepend,
    BCard,
    BTable,
    BInputGroupAppend,
    BButton,
    BButtonGroup,
    BDropdown,
    BRow,
    BCol,
    BContainer,
    BFormGroup,
    BPagination,
    BInputGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
  },
  data() {
    return {
      search: "",
      // pagination: {
      //   currentPage: 1,
      //   perPage: 50,
      // },
      pagination: {
        currentPage: 1,
        totalRows: 1,
        perPage: 10,
        perPageOptions: [10, 25, 50, 100],
      },
      fields: [
        {
          key: "sr",
          label: "SR",
          thStyle: { width: "6rem" },
        },
        {
          key: "role_name",
          label: "Role Name",
          sortable: true,
        },
        {
          key: "edit",
          label: "Edit",
          thStyle: { width: "6rem" },
        },
      ],
      RoleLists: [],
    };
  },
  methods: {
    onFiltersChange() {
      this.pagination.currentPage = 1;
      this.onChange();
    },
    onChange() {
      this.$nextTick(() => {
        this.getAllRoles();
      });
    },
    async getAllRoles() {
      try {
        const response = await UserServices.getAllRoles({
          currentPage: this.pagination.currentPage,
          perPage: this.pagination.perPage,
          search: this.search,
        });

        if (response.data.status) {
          if (response.data.data.pagination.total) {
            this.pagination.total = response.data.data.pagination.total;
          }
          this.pagination.currentPage =
            response.data.data.pagination.currentPage;

          this.RoleLists = response.data.data.data;
        }
      } catch (error) {
        console.log("Error in get all roles ", error);
      }
    },
  },
  beforeMount() {
    this.getAllRoles();
  },
};
</script>
